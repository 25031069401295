.footer{
background: #000000;
padding: 100px;
}

.footer h1{
font-family: 'Open Sans', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 80px;
line-height: 116px;
text-align: center;
color: #FFFFFF;
}

.footer p{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 50px;
    text-align: center;
    color: #6BFFF0;
    }

@media (max-width:600px){
    .footer{
        background: #000000;
        padding: 50px;
        }
        
        .footer h1{
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 45px;
        text-align: center;
        color: #FFFFFF;
        }
        
        .footer p{
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #6BFFF0;
            }
}